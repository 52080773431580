@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    display: none;
}

html {
    --cursor-color: #E9F1F7
}

@layer base{
    body{
        @apply font-[Montserrat]

    }
    
    li{
        @apply px-4;
        @apply cursor-pointer;
    }
}